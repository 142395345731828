import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { Ticket } from '../models/ticket';
import { TicketService } from '../services/ticket.service';


@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit {
  @Input() tawkTo: string;
  list: Ticket;
  script: any;
  searchKey: string;
  p: number = 1;
  constructor(private mainService: TicketService,private renderer:Renderer2,@Inject(DOCUMENT) private document) {
    this.script= this.renderer.createElement('script');
  }

  ngOnInit() {
    // this.mainService.getAll().subscribe(data=>{
    //   this.list = data['results']
    // })
    this.script.text =`var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/${this.tawkTo}';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();`;
    this.renderer.appendChild(this.document.body, this.script);
  }



}
