import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';

import { ClientPageRoutingModule } from './client-routing.module';

import { ClientPage } from './client.page';
import { DashboardComponent} from './dashboard/dashboard.component'
import { InvoicesComponent } from './invoices/invoices.component';
import { PackagesComponent } from './packages/packages.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TopupsComponent } from './topups/topups.component';
import { VouchersComponent } from './vouchers/vouchers.component';

import { HttpClientModule } from '@angular/common/http';
import { MessagesComponent } from './messages/messages.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LinkyModule } from 'ngx-linky';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { TopupDetailComponent } from './topup-detail/topup-detail.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ClientPageRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    LinkyModule,
    Ng2SearchPipeModule,
  ],
  declarations: [ClientPage,
    DashboardComponent,
    InvoicesComponent,
    InvoiceDetailComponent,
    PackagesComponent,
    TicketsComponent,
    TopupsComponent,
    TopupDetailComponent,
    ContactDetailComponent,
    VoucherDetailComponent,
    VouchersComponent,
    MessagesComponent
  ],
  exports:[ContactDetailComponent]
})
export class ClientPageModule {}
