import { Component, OnInit } from '@angular/core';
import { NetworkInvoice } from '../models/invoice';
import { InvoiceService } from '../services/invoice.service';
import { ErrorDialogService } from 'src/app/auth/services/error-dialog.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  list:NetworkInvoice
  searchKey:string;
   p: number = 1;
  constructor(private mainService:InvoiceService, private errorService: ErrorDialogService) { }

  ngOnInit() {

    this.mainService.getAll().subscribe(data=>{
      this.list=data['results']
    })
  }

}
