export class AppConfig{
    public readonly api: string ="https://v4.netpap.co.ke/api"
    public readonly url: string="http://v4.netpap.co.ke"

    // public readonly api: string ="http://127.0.0.1:8000/api"
    // public readonly url: string="http://127.0.0.1:8000"

    public readonly pageSize:number=10;
    public readonly pageSizeOptions:Array<number>=[0,10,20,50,100,500]
    public readonly security_token:string = "1234567"
}
