import { Component, OnInit,} from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  detail:string = 'Detail';

  constructor(private authenticationService:AuthenticationService) { }

  ngOnInit() {

  }
  refresh(e) {

    console.log("Refresh")
  }


}
