import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { TopUps } from '../models/topup';
import { TopupsService } from '../services/topups.service';
import { VouchersService } from '../services/vouchers.service';
import { ErrorDialogService } from 'src/app/auth/services/error-dialog.service';

@Component({
  selector: 'app-topup-detail',
  templateUrl: './topup-detail.component.html',
  styleUrls: ['./topup-detail.component.scss'],
})
export class TopupDetailComponent implements OnInit {
  topup:TopUps;
  id:string;
  constructor(private mainService: VouchersService,private authService: AuthenticationService,private route: ActivatedRoute,
     private errorService: ErrorDialogService) { }

  ngOnInit() {
    const voucher: string= this.route.snapshot.paramMap.get('voucher');
    const t = this.authService.getUserDetails();
    this.mainService.refreshVoucher(voucher,t['username'],t['security_token'],'topup').subscribe(data=>{
      this.topup = data;
    });


}

}
