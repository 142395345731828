import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';
import { ErrorDialogService } from '../services/error-dialog.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  companies:Array<Company>=[];
  username:string;
  constructor(private authService: AuthenticationService,private router: Router,private route: ActivatedRoute,
    private errorService: ErrorDialogService) {

  }

  ngOnInit() {
    console.log("Hello")
    this.errorService.hideLoader();
    this.username = this.route.snapshot.paramMap.get("username");
    this.companies = this.authService.getCompaniesArr()
    console.log(this.companies)
    if(this.companies.length==0 || !this.companies){
      console.log("-------------")
      this.router.navigate(['/auth'])

    }
    console.log(this.companies)
  }
  login(company){
    let arr:Array<Company>=[company]
    this.authService.setCompanies(arr)
    this.authService.selectedCompany(company)
    this.router.navigate(['/auth/password',this.username]);
  }

}
