import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthPageRoutingModule } from './auth-routing.module';

import { AuthPage } from './auth.page';
import { LoginComponent } from './login/login.component';
import { PasswordComponent } from './password/password.component';
import { ClientPageModule } from '../client/client.module';
import { CompanyComponent } from './company/company.component';

import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AuthPageRoutingModule, ReactiveFormsModule, ClientPageModule,HttpClientModule
  ],
  declarations: [AuthPage, LoginComponent, PasswordComponent,CompanyComponent,MainLayoutComponent,
    ErrorDialogComponent,ConfirmDialogComponent],

  exports: [MainLayoutComponent,ErrorDialogComponent,ConfirmDialogComponent]
})
export class AuthPageModule {}
