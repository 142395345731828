import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/auth/helpers/app-config';

@Injectable({
  providedIn: 'root'
})
export class ListDataService<T> {

  constructor(public http:HttpClient,public config:AppConfig,@Inject(String) public list_url:string,@Inject(String) public detail_url:string) { }
  getAll(pageNumber:number=0,pageSize:number=10,sortByColumn:string="username",sortAscDesc:string = "asc",filter:string='',filter_params:any={}):Observable<any>{
    console.log(filter,"-------------------------->",pageNumber,pageSize)
    let params:HttpParams = new HttpParams().set("limit",pageSize.toString()).set("offset",(pageNumber * pageSize).toString())
      .set('ordering',`${sortAscDesc == 'desc' ? '-' : ''}${sortByColumn}`)
      if(filter && filter.length){

        params=params.set('search',filter);

      }

    return this.http.get<any>(this.config.api+this.list_url+'/',{params:params})
  }
  getSingle(id: string){
    return this.http.get<any>(this.config.api+this.detail_url+'/'+id);
  }
}
