import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/auth/helpers/app-config';
import { Voucher } from '../models/voucher';
import { ListDataService } from './list-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VouchersService extends ListDataService<Voucher> {

  constructor(public http:HttpClient,public config:AppConfig ) {
    super(http,config,'/client/vouchers','/client/voucher');
   }
   refreshVoucher(voucher:string,username:string,securityToken:string,typ:string='voucher'):Observable<any>{
     let params:HttpParams = new HttpParams()
                    .set('username',username)
                    .set('voucher',voucher)
                    .set('security_token',securityToken)
                    .set('typ',typ)
     return this.http.get(`${this.config.api}/client/customerusage/`,{params:params});
   }
}
