import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { TopUps } from '../models/topup';
import { TopupsService } from '../services/topups.service';
import { ErrorDialogService } from 'src/app/auth/services/error-dialog.service';
import { BaseListComponent } from '../base-list/base-list.component';
import { AppConfig } from 'src/app/auth/helpers/app-config';

@Component({
  selector: 'app-topups',
  templateUrl: './topups.component.html',
  styleUrls: ['./topups.component.scss'],
})
export class TopupsComponent extends BaseListComponent {
  searchKey :string;
  constructor(public mainService:TopupsService,public config:AppConfig,private authService:AuthenticationService,public errorService:ErrorDialogService) {
    super(mainService,config,errorService)
   }
}

