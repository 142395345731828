import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';

import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { Message } from '../models/message';
import { MessagesService } from '../services/messages.service';
import { ErrorDialogService } from 'src/app/auth/services/error-dialog.service';
import { AppConfig } from 'src/app/auth/helpers/app-config';
import { BaseListComponent } from '../base-list/base-list.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent extends BaseListComponent {
  searchKey :string;
  constructor(public mainService:MessagesService,public config:AppConfig,private authService:AuthenticationService,public errorService:ErrorDialogService) {
    super(mainService,config,errorService)
   }
}

