import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthPage } from './auth.page';
import { CompanyComponent } from './company/company.component';
import { LoginComponent } from './login/login.component';
import { PackagesComponent } from './packages/packages.component';
import { PasswordComponent } from './password/password.component';
import { HotspotpageComponent } from './hotspotpage/hotspotpage.component';

const routes: Routes = [
  {
    path: '',
    component: AuthPage,
    children: [
      {path:'', component:LoginComponent},
      {path:'login', component:HotspotpageComponent},

      {path:'networks/:username',component:CompanyComponent},
      {path:'password/:username',component:PasswordComponent},
      {path:'packages',component:PackagesComponent}

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthPageRoutingModule {}
