import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../services/invoice.service';
// import { NetworkInvoice } from '../models/invoice';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NetworkInvoice } from '../models/invoice';
import { Payment } from '../models/payment';
import * as FileSaver from 'file-saver';
import { ErrorDialogService } from 'src/app/auth/services/error-dialog.service';
@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
})
export class InvoiceDetailComponent implements OnInit {
  detail: NetworkInvoice;
  l: Payment[]=[];
  id: string;
  constructor(private invoiceService: InvoiceService,private route: ActivatedRoute, private errorService: ErrorDialogService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    // this.errorService.showLoading();
    this.invoiceService.getSingle(this.id).subscribe(data =>{
      this.detail =data;

    } );
    this.invoiceService.getPayments(this.id).subscribe(data=>{
      this.l =data['results'];
    });
  }
  download(){
    this.invoiceService.downloadInvoice(this.id).subscribe(data=>{

      FileSaver.saveAs(data);
    });
  }

}
