import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthPageModule } from './auth/auth.module';

import {  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfig } from './auth/helpers/app-config';
import { AuthInterceptor } from './auth/helpers/auth-interceptor';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [AppComponent],
  imports: [ BrowserModule,ReactiveFormsModule, IonicModule.forRoot(), AppRoutingModule,AuthPageModule,HttpClientModule],


  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },AppConfig,{
    provide: HTTP_INTERCEPTORS,
    useClass:  AuthInterceptor,
    multi: true
}],
  bootstrap: [AppComponent],

})

export class AppModule {}
