import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dashboard } from '../models/dashboard';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
})
export class ContactDetailComponent implements OnInit {
  dashboard: Dashboard;
  constructor(private router:Router,private authService: AuthenticationService) { }

  ngOnInit() {
    this.dashboard= this.authService.getUserDetails();
  }
  open(endpoint: string){

    this.router.navigate(['/client/'+endpoint]);
  }
}
