import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Voucher } from '../models/voucher';
import { VouchersService } from '../services/vouchers.service';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { ErrorDialogService } from 'src/app/auth/services/error-dialog.service';

@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.scss'],
})
export class VoucherDetailComponent implements OnInit {
  voucher: Voucher;
  id: string;
  constructor(private route: ActivatedRoute,private mainService: VouchersService,private authenticationService: AuthenticationService,
    private errorService: ErrorDialogService) { }

  ngOnInit() {
    const voucher: string= this.route.snapshot.paramMap.get('voucher');
    const t = this.authenticationService.getUserDetails();
    this.mainService.refreshVoucher(voucher,t['username'],t['security_token']).subscribe(data=>{
      this.voucher = data;
    });


  }

}
