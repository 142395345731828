import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { throwError as observableThrowError, Observable,BehaviorSubject, from, throwError } from 'rxjs';
import { catchError, tap, switchMap, filter, take, retry, map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ErrorDialogService } from '../services/error-dialog.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private injector:Injector,private errorDialogService:ErrorDialogService) { }
    private inflightAuthRequest: Observable<any> = null;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const authService = this.injector.get(AuthenticationService);
      request = this.addAuthToken(request);
      console.log(request);
      return next.handle(request)
      .pipe(
        tap(data =>{
          this.errorDialogService.hideLoader();
        }),
        catchError(error => {
          this.errorDialogService.hideLoader();
          console.log(error);
          if(error instanceof HttpErrorResponse){
            console.log(error.status)
              if(error.status === 401){
                if(!this.inflightAuthRequest){
                  this.inflightAuthRequest = authService.refreshToken();
                  if(!this.inflightAuthRequest){
                    this.logout();
                    return throwError(error);
                  }
                  else{
                    return this.inflightAuthRequest.pipe(
                      switchMap((newToken: string) =>{
                        this.inflightAuthRequest = null;

                        const authReq = this.addAuthToken(request);
                        return next.handle(authReq);
                      }),catchError(error => {
                        authService.logout();
                        return throwError(error);
                      })
                    );
                }
              }
                else{
                  this.logout();
                }
              }
              else if(error.status === 400){
                if(error.error instanceof Blob && error.error.type === 'application/json'){
                  return new Promise<any>((resolve,reject) =>{
                    const reader = new FileReader();
                    reader.onload =(e: Event) =>{
                      try{
                        const errmsg = JSON.parse((<any>e.target).result);
                        let messages = this.getErrorMessage(errmsg);
                        this.errorDialogService.openDialog(messages);


                        reject(new HttpErrorResponse({
                          error:errmsg,
                          headers:error.headers,
                          status:error.status,
                          statusText:error.statusText,
                          url:error.url
                        }));

                      }catch(e){
                        console.log("--------------")
                        reject(error)
                      }
                    };
                    reader.onerror = (e) =>{
                      reject(error)
                    }
                    reader.readAsText(error.error);
                  });
                }
                const messages = this.getErrorMessage(error);
                this.errorDialogService.presentToast(messages);
                this.errorDialogService.hideLoader();
                // this.errorDialogService.openDialog(messages);
                return observableThrowError(error);
              }
              else{
                const messages =  this.getErrorMessage(error);
                this.errorDialogService.openDialog(messages);
                return observableThrowError(error);
              }
          }
          return observableThrowError(error);
        })
      )
    }

    logout(){
      localStorage.setItem('token','');
      localStorage.setItem('refreshtoken','');
      localStorage.setItem('user','');
      localStorage.setItem('username','');
      localStorage.setItem('first_name','');
      localStorage.setItem('last_name','');
      localStorage.setItem('company','');
      localStorage.setItem('password_reset','');
      localStorage.setItem('company_name','');

      this.router.navigate(['/auth']);

    }
    refreshToken(){

    }
    addAuthToken(request){

      const token = localStorage.getItem('token');
      const ignore = request.url.includes('refresh') || request.url.includes('obtain') || request.url.includes('accountrecovery') || request.url.includes('rss')
      if(token && !ignore){
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: true,
        });
      }

      return request;
    }


    getErrorMessage(error: HttpErrorResponse): string[]{
      const errorMessage: string[] = [];
      if(error.status !=500 && error.error && typeof (error.error) !== 'string'){
          const errors = Object.values(error.error);
          if(errors){
              for(let index in errors){
              const messages = errors[index];
              if(typeof (messages) == 'string'){
                  errorMessage.push(messages);
              }
              else{
                  for(let message in messages as any[]){
                      errorMessage.push(messages[message]);
                  }
              }
          }

         }
        }

      if(error.status === 500 && typeof(error.error) != 'string'){
          if('detail' in error.error){
              errorMessage.push(error.error['detail'])
          }
          else if('null' in error.error){
              errorMessage.push(error.error['null']);
          }
      }
      if('null' in error){
          errorMessage.push(error['null'])
      }
      if(errorMessage.length == 0){
          errorMessage.push('One or more issues occurred.Please contact administrator')
      }
      return errorMessage;
     }

}







