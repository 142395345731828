import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  data;
  constructor(private modal: ModalController) {

   }
  cancel(){
    return this.modal.dismiss(null,'cancel');
  }


}
