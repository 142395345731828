import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  loading: any;
  constructor(public modalCtrl: ModalController,private toastController: ToastController,private loadingCtrl: LoadingController) { }
  async openDialog(data){

    const modal =  await this.modalCtrl.create({
      component: ErrorDialogComponent,
      handle:false,
      cssClass: 'error-dialog',
      componentProps:{
        data
      }
    });
    modal.present();


    const d = await modal.onWillDismiss().then((_ =>{

    }));
  }

  async presentToast(data){
    let msg = '';
    for(const x of data){
      msg +=x;
    }
    const toast = await this.toastController.create({
      message:msg,
      duration: 3000,
    });
    await toast.present();
    const {role} = await toast.onDidDismiss();
  }

  async showLoading() {
    this.loading = await this.loadingCtrl.create({
      message: 'Loading...',
      spinner: 'circles',
      cssClass: 'custom-loading',
    });

    this.loading.present();
  }
  async hideLoader(){
    console.log("Hide loader")
    try{
     await this.loadingCtrl.dismiss();
    }catch(e){

    }
  }
}
