import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Company, User } from '../models/user';
import { ErrorDialogService } from '../services/error-dialog.service';
import { Feed } from '../models/feed';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  phoneForm: FormGroup;
  users:User;
  status: string="";
  companies: Array<Company>=[];
  feed:any;

  constructor(private router:Router, private fb:FormBuilder,private authService:AuthenticationService,
    private errorService: ErrorDialogService) {}

  ngOnInit(): void {
    let defaultlanding =window.location.hostname;
    // if(!defaultlanding.includes("netpap.co.ke") || !defaultlanding.includes("127.0.0.1")){
    //   this.router.navigate(['/login'])
    // }
    // this.router.navigate(['/login'])
    
    let username = this.authService.getUsername();

    this.phoneForm = this.fb.group({
      username:[username,[Validators.required , Validators.maxLength(20)]],
      // agreement: [false, Validators.requiredTrue]
    })


  }

  getCompanies(){
    let username = this.phoneForm.getRawValue()['username'] as string;

    this.authService.getCompanies(username).subscribe(data=>{
      console.log(this.errorService.hideLoader());
      let count = data['count']
      console.log("--!",data['results'])
      if(count> 1){

        this.authService.setCompanies(data['results'])
        this.router.navigate(['/auth/networks',username])

      }else if(count == 1){
         this.authService.setCompanies(data['results'])
         this.authService.selectedCompany(data['results'][0])
        this.router.navigate(['/auth/password',username])
      }else{
        this.status="Hi,User "+username+" does not exist :("
        this.authService.presentToast('bottom',this.status)
      }

    },error=>{
      this.errorService.hideLoader();
    })
    //fetch companies against this account.

    //if 1 company redirect to password component
    //if more than one company ..show cards the company is in.

  }
  discover(){
    (document.getElementById('discover') as HTMLElement).style.display = 'block';
  }

}
