import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Company, User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';
import { ErrorDialogService } from '../services/error-dialog.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class PasswordComponent implements OnInit {
  passwordForm:FormGroup;
  company:string=""
  username:string=""
  constructor(private router:Router,private route:ActivatedRoute,private errorService:ErrorDialogService,
    private authService:AuthenticationService,private fb:FormBuilder,private modal:ModalController) { }

  ngOnInit() {
    this.username = this.route.snapshot.paramMap.get("username");
    this.company =this.authService.getSelectedCompany();

    console.log(this.company)
    if(this.company){
      this.passwordForm = this.fb.group({
        username:{disabled:true},
        password:['',Validators.required],
        company:{disabled:true}
      })



    }else{
      this.router.navigate(['/auth']);
    }
  }

  login(){
    // this.errorService.showLoading();
    if (!this.username){
      this.router.navigate(['/auth']);
    }
    this.passwordForm.get("username").setValue(this.username);
    this.passwordForm.get("company").setValue(this.company);
    this.authService.login(this.passwordForm.getRawValue() as User).subscribe(data=>{
     //store token in localstorage
    //  this.errorService.hideLoader();
     console.log(data)
     if (data['access']){
     this.authService.setSession(data);
     this.router.navigate(['/client/dashboard']);
     }else{

     }

    })
  }

  async recoverAccount(){

   const modal = await this.modal.create({
     component:ConfirmDialogComponent,

   })
   modal.present();
   const {data,role} = await modal.onWillDismiss();

   if(role == 'confirm'){
    this.errorService.showLoading();
    this.authService.recoverAccount({"username":this.username,"company":this.company}).subscribe(data  =>{
      this.errorService.hideLoader();
      if(data['message']){
      this.authService.presentToast("bottom",data['message'])
      }else{

      }

    });
   }



  }

  back(){
    this.router.navigate(['/auth']);
  }


}
