import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/auth/helpers/app-config';
import { Ticket } from '../models/ticket';
import { ListDataService } from './list-data.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService extends ListDataService<Ticket> {

  constructor(public http:HttpClient,public config:AppConfig ) {
    super(http,config,'/client/tickets','/client/ticketdetail');
   }
}

