import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/auth/helpers/app-config';
import { ErrorDialogService } from 'src/app/auth/services/error-dialog.service';
import { ListDataService } from '../services/list-data.service';

@Component({
  selector: 'app-base-list',
  template: '<p> </p>',
  styleUrls: ['./base-list.component.scss'],
})
export class BaseListComponent implements OnInit {
  p:number =0;
  count:number =0;
  items:any;
  constructor(public mainService: ListDataService<any>,public config:AppConfig,public errorService:ErrorDialogService) { }

  ngOnInit() {
    // this.errorService.showLoading();
    // let details = this.authService.getUserDetails();
    this.getAll(this.p)
  }

  getAll(e){
    this.mainService.getAll(this.p).subscribe(data=>{
      this.items = data['results'];
      this.count =data['count']
      this.p =e
      this.errorService.hideLoader();

    });
  }
  search(e){
    if(e.length >= 3){

    }
    this.mainService.getAll(0,1000,'date_created','desc',e).subscribe(data=>{
      this.items = data['results'];
      this.count =data['count']
      this.p =e
      this.errorService.hideLoader();

    });
  }

}
