import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { Dashboard } from '../models/dashboard';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('slides',{static:true}) slider: IonSlides;
  segment=1;
  dashboard: Dashboard;
  dash='dashboard';
    constructor(private authService: AuthenticationService,private router:Router,private route:ActivatedRoute) { }

  ngOnInit() {


    this.dashboard= this.authService.getUserDetails();
    console.log(this.dashboard,"---------------------------------------------------!!!!----------");
  }

  open(endpoint: string){

    this.router.navigate(['/client/'+endpoint]);
  }
  async segmentChanged(ev: any){
    console.log(ev);
    await this.slider.slideTo(this.segment);
  }
  async slideChanged(){
    this.segment = await this.slider.getActiveIndex();
    console.log(this.segment);
  }
  logout(){
    this.authService.logout();
  }

}
