import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/auth/helpers/app-config';
import { NetworkInvoice } from '../models/invoice';
import { ListDataService } from './list-data.service';
import { Payment } from '../models/payment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends ListDataService<NetworkInvoice> {

  constructor(public http:HttpClient,public config:AppConfig){
    super(http,config,'/client/invoices','/client/invoice');
   }

   getPayments(id:string):Observable<Payment>{
     return this.http.get<Payment>(`${this.config.api}/client/invoicepayments/${id}/`);
   }
   downloadInvoice(id:string){
     return this.http.get(`${this.config.api}/invoicing/generatenetworkinvoice/${id}/`,{ responseType: 'blob' });
   }
}

