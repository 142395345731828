import { HttpBackend, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';
import { AppConfig } from '../helpers/app-config';
import { Company, User } from '../models/user';
import { Feed } from '../models/feed';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  companies:Array<Company>=[];

  private ht: HttpClient;

  constructor(private router: Router,private config: AppConfig, private handler: HttpBackend,
              private http: HttpClient,private toastController: ToastController) {
    this.ht = new HttpClient(this.handler);

  }
getCompanies(username:string):Observable<User>{
  let params:HttpParams = new HttpParams().set('username',username);
  return this.ht.get<User>(`${this.config.api}/client/mobilequery/`,{params:params})
}
login(user: User){
  return this.http.post(`${this.config.api}-auth/token/obtain/`,user)
  .pipe(
    tap((response: Response) => {
      const authResult = response as any;
      if(authResult  && authResult.access){
        this.setSession(authResult);
      }
    }),catchError(this.handleError)
    );
}

logout(){
  localStorage.setItem('token','');
  localStorage.setItem('refreshtoken','');
  localStorage.setItem('user','');
  localStorage.setItem('username','');
  localStorage.setItem('first_name','')
  localStorage.setItem('last_name','')
  localStorage.setItem('company','')
  localStorage.setItem('password_reset','')
  localStorage.setItem('company_name','')

  this.router.navigate(['/auth'])

}
setCompanies(c:Array<Company>){
  this.companies=c;
}
getCompaniesArr():Array<Company>{
  return this.companies;
}

async presentToast(position: 'top' | 'middle' | 'bottom',message:string) {
  const toast = await this.toastController.create({
    message: message,
    duration: 1500,
    position: position
  });

  await toast.present();
}
setSession(r){
  localStorage.setItem('token',r.access);
  localStorage.setItem('refreshtoken',r.refresh);
  const u = this.parseToken(r.access);
  localStorage.setItem('user',u.user);
  localStorage.setItem('username',u.username);
  localStorage.setItem('first_name',u.first_name)
  localStorage.setItem('last_name',u.last_name)
  localStorage.setItem('company',u.company_id)
  localStorage.setItem('password_reset',u.password_reset)
  localStorage.setItem('company_name',u.company_name)

}
parseToken(token:string){
  let base64Url:string = token.split('.')[1];
  let base64:string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload:any = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload)
}
getUserDetails(){
  let token = this.parseToken(localStorage.getItem('token'))
  return token
}
recoverAccount(user){
  return this.http.post(`${this.config.api}/client/accountrecovery/`,user)
}
selectedCompany(company:Company){
  console.log(company)
  localStorage.setItem('company',company.company);
}
getSelectedCompany():string{
  return  localStorage.getItem('company');
}

getToken(){
  return  localStorage.getItem('token');
}
getUsername(){
  return  localStorage.getItem('username');
}
refreshToken(){
  var theToken = this.getToken();
  var refresh = this.getRefreshToken()

  return this.http.post(this.config.api+'-auth/token/refresh/',{refresh:refresh})
  .pipe(
    share(),
    map(response =>{
      let authResult = response as any;
      if(authResult && authResult.access){
        this.setSession(authResult);
        return authResult.access
      }
      return null;

    }),
    catchError(this.handleRefreshError)
    );
}

getRefreshToken() {
  var theToken = localStorage.getItem('refresh_token');
  return theToken;
}


public getFeed(){
  return this.http.get(this.config.api+'/bridge/rss/');
}

private handleError(error: HttpErrorResponse | any) {
  console.error('An error occurred.', error);
  // alert(error.message || error);
  return throwError(error.message || error)
}
private handleRefreshError(error: HttpErrorResponse | any) {
  console.error('A refresh error occurred.', error);
  // alert('refresh: '+error.message || error);
  return throwError(error.message || error)
}


}
