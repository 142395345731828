import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  message:string="Are you sure you want to continue?"
  constructor(private modal:ModalController) { }

  ngOnInit() {}

  cancel(){
    return this.modal.dismiss(null,'cancel');
  }
  confirm(){
    return this.modal.dismiss(this.message,"confirm")
  }

}
