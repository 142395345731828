import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private titleService:Title) {
    let company: string = localStorage.getItem('company_name');
    if(!company){
      company = 'Netpap';
    }
    this.titleService.setTitle(company);
  }

}
