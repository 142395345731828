import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/helpers/auth.guard';
import { MainLayoutComponent } from '../auth/main-layout/main-layout.component';

import { ClientPage } from './client.page';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { MessagesComponent } from './messages/messages.component';
import { PackagesComponent } from './packages/packages.component';

import { TicketsComponent } from './tickets/tickets.component';
import { TopupDetailComponent } from './topup-detail/topup-detail.component';
import { TopupsComponent } from './topups/topups.component';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { VouchersComponent } from './vouchers/vouchers.component';

const routes: Routes = [
  {
    path: '',
    component: ClientPage,
    children:[
      {path:'dashboard', component:DashboardComponent},

      // {path:'mlist',component:MessagesComponent},

      // {path:'topups',component:TopupsComponent},
      // {path:'invoices',component:InvoicesComponent},
      // {path:'packages',component:PackagesComponent},
      // {path:'tickets',component:TicketsComponent}

      {path:'',
      component: MainLayoutComponent,
      children:[
        {path:'messages',component:MessagesComponent},
        {path:'vouchers',component:VouchersComponent},
        {path:'vouchers/detail/:voucher',component:VoucherDetailComponent},

        {path:'topups',component:TopupsComponent},
        {path:'topups/detail/:username',component:TopupDetailComponent},

        {path:'invoices',component:InvoicesComponent},
        {path:'invoices/detail/:id',component:InvoiceDetailComponent},

        {path:'packages',component:PackagesComponent},
        {path:'tickets',component:TicketsComponent},
        {path:'notifications',component:MessagesComponent}
      ]
      }
      // Components below might have children

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientPageRoutingModule {}
