import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/auth/helpers/app-config';
import { Message } from '../models/message';
import { ListDataService } from './list-data.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesService extends ListDataService<Message> {

  constructor(public http:HttpClient,public config:AppConfig) {
    super(http,config,'/client/messages','/client/messagedetail');
   }

  // getMessages(id:string):Observable<Message>{
  //   return this.http.get<Message>(`${this.config.api}/client/messages/${id}/`)
  // }
}
