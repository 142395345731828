import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { Voucher } from '../models/voucher';
import { VouchersService } from '../services/vouchers.service';
import { ErrorDialogService } from 'src/app/auth/services/error-dialog.service';
import { BaseListComponent } from '../base-list/base-list.component';
import { config } from 'rxjs';
import { AppConfig } from 'src/app/auth/helpers/app-config';

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss'],
})
export class VouchersComponent extends BaseListComponent {
  vouchers: Voucher;
  searchKey: string;


  constructor(public mainService: VouchersService,public config:AppConfig,public authService:AuthenticationService,public errorService: ErrorDialogService) {
    super(mainService,config,errorService,)
  }






}
